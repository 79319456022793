.cr-calendar-input {
  position: relative;

  .ms-TextField {
    z-index: 1;
  }

  .ms-TextField-fieldGroup {
    border-radius: 0;
  }

  &.is-active {
    .ms-TextField-fieldGroup {
      border-color: $color-primary;
      outline: 1px solid $color-primary;
    }
  }
}

.cr-calendar {
  display: block;
  padding: 1rem;
  background: none;

  .cr-calendar-header {
    display: flex;
    margin-bottom: 0.85rem;

    .before,
    .next,
    .close {
      display: flex;

      .ms-Button.is-disabled {
        background: transparent;
      }
    }

    .before {
      justify-content: flex-start;
      margin-right: 0.5rem;
    }

    .next {
      justify-content: flex-end;
      margin-left: 0.5rem;
    }

    .close {
      margin-left: 2rem;
    }

    > .cr-icon-button {
      max-width: 50px;
    }

    .cr-calendar-navi {
      display: flex;
      flex-basis: 100%;
      align-items: center;
      justify-content: center;

      .year,
      .month {
        display: flex;
        align-items: center;
        justify-content: center;
        .cr-dropdown {
          margin-right: 0.3rem;
        }
      }

      .year {
        .cr-dropdown {
          width: 5rem;
        }
      }

      .month {
        margin-left: 0.7rem;

        .cr-dropdown {
          .ms-Dropdown {
            width: 57px;
          }
        }
      }
    }
  }

  .cr-calendar-typeable {
    display: flex;
    width: 100%;
    margin-bottom: 4px;

    .--input {
      width: 100%;
      margin-right: 4px;
    }
  }

  .cr-calendar-dates {
    table {
      width: 100%;
      border-collapse: collapse;
    }

    th,
    td {
      padding: 0;
      border: 1px solid #ccc;
      text-align: center;

      button {
        $-size: 30px;
        $-width: calc(#{$-size} + 4px);
        $-height: $-size;
        position: relative;
        width: 100%;
        min-width: $-width;
        height: $-height;
        margin: 0;
        padding: 0;
        border: none;
        background: transparent;
        color: inherit;
        line-height: $-height;
        text-align: center;
        cursor: pointer;

        &:hover {
          background: transparent;
        }

        &:disabled {
          opacity: 0.2;
          cursor: not-allowed;
        }
      }
    }

    th {
      padding: 0.5rem 0;
      font-size: $font-size-xs;
    }

    td {
      &:hover {
        background: #f7f7f7;
      }
    }

    .--ghost {
      button {
        opacity: 0.6;
      }
    }

    .--sun {
      color: $color-red-700;
    }

    .--sat {
      color: $color-blue-700;
    }

    .--today {
      background: $color-orange-100;
      color: $color-orange-600;

      button {
        line-height: 43px;

        &:after {
          content: "TODAY";
          position: absolute;
          top: 5px;
          right: 0;
          left: 0;
          font-size: 8px;
          font-family: verdana;
          line-height: 100%;
          text-align: center;
        }
      }
    }

    .--selected {
      background: $color-primary;
      color: #fff;

      &:hover {
        background: darken($color-primary, 3%);
      }
    }
  }

  .cr-calendar-enter {
    width: 100%;
  }
}

.cr-calendar-ext {
  padding: 1rem;

  .cr-calendar {
    padding: 0;
  }

  .cr-calendar-uk {
    display: flex;
    flex-basis: 100%;
    margin: 4px 0 0 -4px;

    > button {
      display: flex;
      flex-basis: 100%;
      align-items: center;
      justify-content: center;
      box-sizing: border-box;
      margin: 0 0 0 4px;
      padding: 0;
      border-radius: 0;
      outline: none;

      span {
        font-weight: 600;
        font-size: $font-size-xs;
      }

      .day {
        margin-left: 3px;
      }

      &.break {
        span {
          display: flex;
          flex-basis: 100%;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        }

        .day {
          margin-left: 0;
        }
      }
    }
  }
}

.cr-calendar-filter {
  margin-bottom: 1rem;
  display: flex;
  justify-content: center;

  .year {
    width: 5rem;
    margin-right: 0.7rem;
  }

  .cr-icon {
    margin: 0.5rem;
  }
}

.ms-Dropdown-callout {
  &.cr-calendar-navi--year {
    width: 5rem;

    .ms-Button-flexContainer {
      justify-content: center;
    }
  }
}

.cr-calendar-time-callout {
  .cr-calendar-time-ext {
    padding: 1rem;

    .cr-calendar {
      padding: 0;
    }

    .cr-calendar-time-spin-buttons {
      display: flex;
      flex-basis: 100%;
      gap: 10px;
      align-items: center;
      justify-content: center;
      box-sizing: border-box;

      > div {
        flex-basis: 100%;
        width: 6rem;
        ::after {
          border-color: #ccc;
        }
      }

      > span {
        padding-top: 25px;
      }

      .ms-Label {
        font-weight: 600;
        font-size: 0.69rem;
      }

      .ms-Button {
        cursor: pointer;
      }
    }

    .cr-calendar-time-buttons {
      display: flex;
      flex-basis: 100%;
      margin-top: 6px;

      > button {
        display: flex;
        flex-basis: 100%;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        padding: 0;
        border-radius: 0;
        outline: 1px solid #259ac5;

        span {
          font-weight: 600;
          font-size: 0.69rem;
        }
      }
    }
  }
}
