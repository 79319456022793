.maven-clinical-cloud--icon {
  display: inline-block;
  width: 30px;
  height: 25px;
  background-image: url("../image/logo_maven_icon_circle_blue.png");
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
  text-indent: -9999px;
}

.maven-clinical-cloud--maven-docs {
  display: inline-block;
  width: 130px;
  height: 25px;
  background-image: url("../image/logo_maven_docs_text_blue.png");
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
  text-indent: -9999px;
}

.app-home-dashboard {
  .cr-section-inner-title-right {
    .ms-Dropdown {
      width: 11rem !important;
    }
  }
}

.app-dashboard-notice-summary {
  .cr-section-inner {
    overflow: visible;
    padding: 0;
    background: none;

    .cr-section-inner {
      padding: 1rem;
      background: #fff;
    }

    .cr-grid-item {
      margin-top: 0;
      margin-bottom: 0;
    }

    .announce {
      display: flex;
      flex-basis: 0;
      align-items: center;
      margin-right: 0;
      padding: 0 13px;
      border-radius: 5px;
      background: #505050;
      color: #fff;
      cursor: default;

      .material-icons-outlined {
        font-size: calc(#{$font-size-xl} * 1.5);
      }
    }

    .notice {
      margin-left: 0;

      .cr-section {
        padding: 0;

        .cr-section-inner {
          padding: 0;
        }
      }

      .article {
        position: relative;
        display: block;
        flex-basis: 100%;
        padding: 1rem;
        padding-right: 30px;
        color: #3f3f3f;
        line-height: 150%;
        text-decoration: none;
        word-break: keep-all;

        em {
          margin-left: 8px;
          color: $color-gray-700;
          font-style: normal;
          font-size: $font-size-xs;
        }

        &:hover {
          color: #000;

          em {
            color: $color-gray-900;
          }
        }

        &.none {
          &:after {
            display: none;
          }
        }

        &:after {
          content: "chevron_right";
          position: absolute;
          top: 50%;
          right: 15px;
          height: 20px;
          margin-top: -10px;
          font-size: 1rem;
          font-family: "Material Icons Outlined";
          line-height: 20px;
        }
      }
    }
  }
}

/* Study > Data > eCRF */
.app-study-data-crf {
  /*.cr-table div:not(.ms-DetailsRow-cellCheck) {
    width: auto !important;
  }*/

  .cr-table {
    .ms-DetailsRow-cell,
    .ms-DetailsHeader-cell {
      width: auto !important;
    }

    .ms-DetailsHeader-cell.is-actionable {
      min-width: 120px;

      @media (max-width: 1000px) {
        min-width: 110px;
      }

      @media (max-width: 900px) {
        min-width: 100px;
      }
    }

    .ms-DetailsRow-cell.ms-DetailsRow-cellCheck {
      width: 48px !important;
      min-width: 48px;
    }

    .ms-DetailsRow {
      width: 100%;
      min-width: 10px !important;
    }
  }
}

.app-comment-dialog {
  .--edited::after {
    content: "(edited)";
    margin-left: 0.25rem;
    color: gray;
    font-size: $font-size-xs;
  }
}

/* Study > Subject > eCRF */
.app-study-subject-crf {
  > .cr-grid {
    .cr-grid-item:last-child {
      max-width: calc(100% - 250px - 2rem);
    }
  }

  &.--loading {
    .--left {
      position: relative;

      &:before,
      &:after {
        content: "";
        position: absolute;
        top: 1.1rem;
        right: 0.77rem;
        left: 0.77rem;
        height: 1.69rem;
        border: 1px solid #cccccc;
        border-radius: 0.15rem;
      }

      &:after {
        top: 3.1rem;
      }

      .cr-section-inner {
        padding: 0;
      }

      .cr-section-inner-wrap {
        margin: 5.4rem 1rem 1rem 1rem;
      }
    }

    .--right {
      .cr-min-width-inner {
        padding: 1rem;
      }
    }
  }

  &.--enroll {
    > .cr-grid {
      .cr-grid-item:last-child {
        max-width: calc(100%);
      }
    }
  }

  .--left {
    $-inner-padding-x: calc(#{$app-content-box-padding} * 0.7);
    $-inner-padding-y: $app-content-box-padding;

    .cr-dropdown {
      .ms-Dropdown {
        font-size: $font-size-sm;

        .ms-Dropdown-title {
          height: 29px;
          line-height: 27px;
        }
      }
    }

    .cr-section-inner {
      max-width: 100%;
      padding: 0 $-inner-padding-x $-inner-padding-y $-inner-padding-x;
    }

    .cr-section-inner-wrap {
      position: relative;

      .--selector {
        position: sticky;
        top: 0;
        z-index: 3;
        padding-top: $-inner-padding-y;
        background: #fff;
        box-shadow: 0 0.2rem 0.3rem #fff;
      }
    }

    .--stage {
      padding-top: 0.5rem;
    }
  }

  .--right {
    &.--with-site {
      .cr-min-width-inner {
        height: calc(100vh - 22.5rem);
      }
    }
  }
}

.app-study-subject-crf-schedule-wrap {
  > .cr-min-width-inner {
    background: #fff;
  }
}

.app-study-subject-crf-schedule-status-legend {
  position: absolute;
  top: -60px;
  right: 0;
  left: 0;
  height: 55px;
  padding: 6px 0;

  .types--crf-stage {
    $-icon-size: 20px;
    display: flex;
    justify-content: flex-end;

    .type--crf-stage {
      position: relative;
      display: flex;
      align-items: center;
      height: 50px;
      margin-right: 0.7rem;

      @media (max-width: 1400px) {
        margin-right: 0.5rem;
      }

      @media (max-width: 1250px) {
        label {
          display: none;
        }
      }

      &:last-child {
        margin-right: 0;
      }

      label {
        margin-right: -0.1rem;
        margin-left: 0.5rem;
        font-weight: bold;
        font-size: $font-size-sm;
        white-space: nowrap;

        @media (max-width: 1400px) {
          margin-left: 0.3rem;
        }
      }

      em {
        margin-left: 0.2rem;
        font-weight: normal;
        font-style: normal;
        font-size: $font-size-sm;
        white-space: nowrap;

        span {
          font-size: $font-size-xs;
          white-space: nowrap;
        }
      }
    }

    .type--inner-crf-stage {
      position: relative;
      top: auto;
      left: auto;
      margin: auto;
    }
  }
}

@media (max-width: 850px) {
  .app-study-subject-crf-schedule-status-legend {
    display: none;
  }
}

.app-study-subject-crf-schedule {
  .cr-table-wrapped {
    overflow: inherit;
  }

  .ms-DetailsList-headerWrapper {
    position: sticky;
    top: 0;
    z-index: 1;
  }

  .ms-DetailsHeader {
    display: inline-flex;
    align-items: center;
    height: auto;
    min-height: 42px;
  }

  .ms-DetailsHeader-cell {
    display: flex;
    align-items: center;
    justify-content: center;
    height: auto;
    min-height: 42px;

    &:first-child {
      justify-content: flex-start;

      .cr-table-header-column-title {
        padding-left: 0;
      }
    }

    .cr-table-header-column {
      position: relative;
      height: 100%;
    }

    .cr-table-header-column-title {
      justify-content: center;
      height: auto !important;

      .cr-table-header-column-label {
        padding-top: 5px;
        padding-bottom: 5px;
        text-align: center;
        word-break: keep-all;
      }
    }
  }

  .ms-DetailsRow-fields,
  .ms-DetailsRow-cell {
    position: relative;
    z-index: 0;
  }

  .ms-DetailsHeader,
  .ms-DetailsHeader-cell:first-child,
  .ms-DetailsRow-fields,
  .ms-DetailsRow-cell:first-child {
    position: sticky;
    left: 0;
    z-index: 1;
    background: #fff;
  }

  .ms-DetailsRow,
  .ms-DetailsRow-fields {
    width: 100%;
  }

  .ms-DetailsHeader-cell:first-child,
  .ms-DetailsRow-cell:first-child {
    min-width: 150px;
  }

  .ms-DetailsHeader-cell,
  .ms-DetailsRow-cell {
    min-width: 50px;
  }

  .ms-DetailsRow {
    &:hover {
      background: #fff !important;
    }
  }
}

.app-study-subject-crf-schedule {
  @mixin distribute_visits($threshold) {
    @for $i from 2 through $threshold {
      &.--v#{$i} {
        .ms-DetailsHeader-cell:first-child,
        .ms-DetailsRow-cell:first-child {
          width: 30% !important;
        }
        .ms-DetailsHeader-cell,
        .ms-DetailsRow-cell {
          width: calc(70% / #{$i}) !important;
        }
      }
    }

    @for $i from $threshold + 1 through 50 {
      &.--v#{$i} {
        .ms-DetailsHeader-cell:first-child,
        .ms-DetailsRow-cell:first-child {
          width: 200px !important;
        }
        .ms-DetailsHeader-cell,
        .ms-DetailsRow-cell {
          width: 100px !important;
        }
      }
    }
  }

  @include distribute_visits($threshold: 3);

  @media (min-width: 1280px) {
    @include distribute_visits($threshold: 5);
  }

  @media (min-width: 1600px) {
    @include distribute_visits($threshold: 8);
  }

  @media (min-width: 1920px) {
    @include distribute_visits($threshold: 11);
  }

  &.--v1 {
    .ms-DetailsHeader-cell:first-child,
    .ms-DetailsRow-cell:first-child {
      width: 50% !important;
    }
    .ms-DetailsHeader-cell,
    .ms-DetailsRow-cell {
      width: 50% !important;
    }
  }
}

/* Study > Subject > eCRF > Form */
.app-study-crf-wrap {
  .cr-section-inner {
    padding: 0;
    background: none;
    box-shadow: none;

    .cr-section.--cont {
      margin-top: -1px !important;
    }
  }

  .cr-section {
    margin: 0 !important;
  }

  .cr-section-inner-content {
    .cr-section {
      margin-top: 0.45rem !important;
    }
  }

  .cr-section-inner-title {
    margin: 0 0 1rem 0 !important;
    padding: 0 !important;
    border: none !important;
    font-size: $font-size-lg !important;
  }
}

.app-study-crf--domain-ecoa {
  td.label {
    ul.--description {
      margin-left: 0;
    }
  }

  .item.type--single-select {
    .cr-clearable-radio-buttons {
      flex-direction: column !important;
      align-items: flex-start;
      margin-left: 0;

      .cr-clearable-radio-button-wrapper {
        margin-top: 8px;

        &:first-child {
          margin-top: 0;
        }
      }

      .ms-Checkbox {
        margin-left: 0;
      }
    }
  }
}

.app-study-crf-signature {
  position: absolute;
  top: 0;
  right: 3px;
  display: flex;
  flex-direction: row;

  & .app-study-crf-signed-at {
    font-style: normal;
    font-size: 11px;
    font-family: verdana;

    &:before {
      content: "Signed at.";
      display: inline-block;
      margin-right: 5px;
      padding: 4px;
      background: yellow;
      font-weight: bold;
    }
  }

  & .app-study-crf-signed-by {
    padding-left: 5px;
    font-style: normal;
    font-size: 11px;
    font-family: verdana;

    &:before {
      content: "By.";
      display: inline-block;
      margin-right: 5px;
      padding: 4px;
      background: yellow;
      font-weight: bold;
    }
  }
}

.app-study-crf-items {
  $-border-color: #ddd;
  $-text-default-size: 200px;

  @keyframes yellow-flash-once {
    0% {
      background: fff;
    }
    5% {
      background: yellow;
    }
    95% {
      background: yellow;
    }
    100% {
      background: #fff;
    }
  }
  @keyframes top-to-bottom {
    from {
      top: -20px;
      opacity: 0;
    }

    to {
      top: 0px;
      opacity: 1;
    }
  }

  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;

  .--item-x1,
  .--appearance-separated .--item-x1 > * {
    flex-basis: 100%;
  }
  .--item-x2,
  .--appearance-separated .--item-x2 > * {
    flex-basis: 50%;
  }
  .--item-x3,
  .--appearance-separated .--item-x3 > * {
    flex-basis: 33.333333%;
  }
  .--item-x4,
  .--appearance-separated .--item-x4 > * {
    flex-basis: 25%;
  }
  .--item-x5,
  .--appearance-separated .--item-x5 > * {
    flex-basis: 20%;
  }
  .--item-x6,
  .--appearance-separated .--item-x6 > * {
    flex-basis: 16.6666666667%;
  }
  .--item-x7,
  .--appearance-separated .--item-x7 > * {
    flex-basis: 14.2857142857%;
  }
  .--item-x8,
  .--appearance-separated .--item-x8 > * {
    flex-basis: 12.5%;
  }
  .--item-x9,
  .--appearance-separated .--item-x9 > * {
    flex-basis: 11.1111111111%;
  }
  .--item-x10,
  .--appearance-separated .--item-x10 > * {
    flex-basis: 10%;
  }
  .--item-x11,
  .--appearance-separated .--item-x11 > * {
    flex-basis: 9.0909090909%;
  }
  .--item-x12,
  .--appearance-separated .--item-x12 > * {
    flex-basis: 8.3333333333%;
  }
  .--item-x13,
  .--appearance-separated .--item-x13 > * {
    flex-basis: 7.6923076923%;
  }
  .--item-x14,
  .--appearance-separated .--item-x14 > * {
    flex-basis: 7.1428571429%;
  }
  .--item-x15,
  .--appearance-separated .--item-x15 > * {
    flex-basis: 6.6666666667%;
  }

  .--appearance-default {
    flex-basis: auto;
  }

  .--highlight,
  .--highlight td.label,
  .--highlight td.item {
    animation: yellow-flash-once 7s ease-in-out 0s;
  }

  .misc {
    > div,
    > div > div {
      display: flex !important;
      flex-basis: auto !important;
      justify-content: center !important;
    }
  }

  .item-status {
    width: 85px;

    > div,
    > div > div {
      display: flex !important;
      flex-basis: auto !important;
      justify-content: center !important;
    }
  }

  caption {
    display: none;
  }

  th,
  td {
    padding: 0.7rem 0.65rem;
    border: 1px solid $-border-color;
    background: #fff;
    text-align: left;

    &.item {
      word-break: keep-all;
    }

    &.--background-gray {
      background-color: $color-gray-200;
    }

    &.label {
      line-height: 145%;
      word-break: keep-all;

      em {
        margin-left: 0.3rem;
        color: $color-red-600;
        font-style: normal;
      }

      &.--bold {
        font-weight: bold;
      }

      &.--indent-1 {
        padding-left: 2rem;
        text-indent: -12px;
      }

      &.--indent-2 {
        padding-left: 2.9rem;
        text-indent: -12px;
      }

      &.--indent-3 {
        padding-left: 3.8rem;
        text-indent: -12px;
      }

      .--description {
        margin: 0.5rem 0 0 0.5rem;
        padding: 0;
        list-style: none;

        li {
          line-height: 170%;
        }

        &.--display-inline {
          display: inline;
          margin: 0 0 0 0.2rem;
          color: #606060;

          li {
            display: inline;
          }
        }

        &.--display-block {
          display: block;
          margin: 0;
          color: #606060;
        }

        &.--display-unordered {
          display: block;
          color: #606060;

          li {
            position: relative;
            padding-left: 1rem;

            &:before {
              content: "";
              position: absolute;
              top: 9px;
              left: 5px;
              width: 3px;
              height: 3px;
              background: #606060;
            }
          }
        }

        &.--display-ordered {
          display: block;
          color: #606060;
          list-style: decimal;

          li {
            position: relative;
            margin-left: 0.8rem;
            padding-left: 0.2rem;
          }
        }
      }
    }
  }

  tr.query {
    td {
      position: relative;
      height: 55px;
      padding-left: 1rem;
      background: $color-red-100;
      color: $color-red-600;
    }

    .message {
      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 3px;
        height: 100%;
        background: $color-red-600;
      }
    }

    .message-inner {
      display: flex;
      > div {
        display: block !important;
        padding-right: 50px;
        line-height: 145%;
      }

      .--link {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        display: flex !important;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        width: 50px;
        margin: 0;
        padding: 0;
        border-left: 1px solid #ddd;
        text-align: right;
      }
    }

    > td {
      > div {
        .--issuer {
          color: $color-red-700;
        }
      }
    }

    &.resolving {
      td {
        background: $color-yellow-100;
        color: $color-yellow-700;
        > div {
          .--issuer {
            color: $color-yellow-800;
          }
        }
      }

      .message {
        &:before {
          background: $color-yellow-600;
        }
      }
    }

    p {
      position: relative;
      margin: 0.4rem 0 0 1.2rem;
      color: $color-yellow-900;
      line-height: 165%;

      &:before {
        content: "chevron_right";
        position: absolute;
        top: 0;
        left: -1.1rem;
        display: inline-block;
        font-size: $font-size-xl;
        font-family: "Material Icons Outlined";
      }
    }
  }

  .single-append {
    padding: 0;

    .ms-Button {
      display: flex;
      flex-basis: 100%;
      justify-content: center;
      box-sizing: content-box;
      height: 40px;
      border: none;
    }
  }

  .cr-input {
    .ms-TextField-fieldGroup {
      max-width: $-text-default-size;
    }

    &.--full {
      .ms-TextField-fieldGroup {
        max-width: 100%;
      }
    }
  }

  .app-study-crf-group-header {
    > th {
      word-break: keep-all;
    }

    .crf-group-header-wrapper {
      display: flex;
      align-items: center;

      &:hover {
        cursor: pointer;
      }

      .crf-group-header-icon {
        margin-right: 10px;
        font-size: 8px;
      }
    }

    th.item-status div {
      display: flex;
      flex-basis: auto;
      justify-content: center;
      font-size: 1.2rem;

      i {
        color: $color-sdv;
      }
    }
  }

  .item-foldable-table {
    .bg-for-exist-queries {
      background: $color-red-100;
    }
  }

  .type--foldable-table,
  .type--appendable-table {
    td.item {
      vertical-align: top;
    }

    .type--sequence {
      padding-top: 1.2rem;
      padding-bottom: 1.2rem;
    }

    .type--check.--item-1 {
      padding-top: 1.1rem;
      padding-bottom: 1.1rem;
    }

    .type--single-select.--layout-radio.--align-default {
      padding-top: 1.1rem;
      padding-bottom: 1.1rem;
    }
  }

  .type--check {
    &.--align-left {
      .item--wrapper {
        justify-content: flex-start !important;
        text-align: left;
      }
    }
  }

  .type--signature {
    .ms-TextField-wrapper {
      display: flex;

      > .ms-TextField-fieldGroup {
        position: relative;
        width: 100%;
        max-width: 250px;
      }
    }
  }

  .type--single-select-dropdown {
    position: relative;
    display: block !important;
    width: 100%;
    max-width: 270px;

    .--wrapped-text {
      &:focus {
        outline: none;
      }

      &.--disabled {
        background: #f7f7f7;
        color: #a19f9d;
      }
    }

    &.is-active {
      .--wrapped-text {
        border-color: $color-primary;
        outline: 1px solid $color-primary;
      }
    }
  }

  .type--dictionary {
    position: relative;
    & .--input {
      width: 100%;
      max-width: 270px;
    }

    .--wrapped-text {
      &:after {
        content: "library_books";
      }

      &:focus {
        outline: none;
      }

      &.--disabled {
        background: #f7f7f7;
        color: #a19f9d;
      }
    }

    &.is-active {
      .--wrapped-text {
        border-color: $color-primary;
        outline: 1px solid $color-primary;
      }
    }
  }

  .type--attachment {
    position: relative;
    width: 100%;

    .item--wrapper {
      .type--attachment {
        display: flex;
      }
    }

    .type--attachment-button {
      flex-grow: 0;
      flex-shrink: 0;
      margin-left: 5px;
    }

    .--wrapped-text {
      &:after {
        content: "attach_file";
      }

      &:focus {
        outline: none;
      }

      &.--disabled {
        background: #f7f7f7;
        color: #a19f9d;
      }

      &.upload--uploading {
        &:after {
          content: "pending";
        }
      }

      &.upload--done {
        &:after {
          content: "pending";
          color: $color-primary;
        }
      }

      &.upload--error {
        &:after {
          content: "close";
          color: $app-color-danger;
        }
      }

      &.upload--checksum {
        &:after {
          content: "check";
          color: $color-primary;
        }
      }
    }

    .cr-input {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      overflow: hidden;

      .ms-TextField-fieldGroup {
        max-width: none;
        border: none;
        background: transparent;
        opacity: 0.001;
      }

      input {
        border: transparent;
        background: transparent;
        opacity: 0.001;
      }
    }

    &.is-active {
      .--wrapped-text {
        border-color: $color-primary;
        outline: 1px solid $color-primary;
      }
    }
  }

  .type--single-select {
    &.--align-left {
      .item--single-select {
        display: inline;
        justify-content: left;
      }
    }
    .--type-text {
      margin-left: 1.5rem;

      .ms-TextField-fieldGroup {
        max-width: 500px;
      }

      &.--base {
        .ms-TextField-fieldGroup {
          max-width: 250px;
        }
      }

      &.--double {
        .ms-TextField-fieldGroup {
          max-width: 400px;
        }
      }

      &.--full {
        .ms-TextField-fieldGroup {
          max-width: 500px;
        }
      }
    }

    .--with-desc.--block {
      .--desc {
        margin-top: 11px;
        margin-right: 10px;
        margin-left: 10px;
      }
    }

    .--appearance-separated {
      display: flex;
      flex-basis: 100%;
      flex-direction: row !important;
      align-items: stretch;

      .cr-clearable-radio-button-wrapper {
        position: relative;
        display: flex;
        flex-basis: 50%;
        justify-content: center;
        padding: 0.7rem 0.65rem;

        &:before {
          content: "";
          position: absolute;
          top: -200%;
          bottom: -200%;
          left: 0;
          width: 1px;
          background: $-border-color;
        }

        &:first-child {
          &:before {
            display: none;
          }
        }
      }
    }
  }

  .--item-time {
    display: flex;

    align-items: center;

    .--prefix {
      margin-right: 0.25rem;
      font-size: $font-size-base;
    }

    .--suffix {
      margin-left: 0.25rem;
      font-size: $font-size-base;
    }
    .--delimiter {
      font-size: $font-size-base;
    }

    .cr-dropdown:nth-of-type(1) {
      margin-right: 0.25rem;
    }
    .cr-dropdown:nth-of-type(2) {
      margin-left: 0.25rem;
    }

    .cr-dropdown {
      display: inline-block;
      min-width: 100px;
      max-width: 100px;
    }
  }

  .--item-double-text {
    display: flex;
    align-items: center;

    .--delimiter {
      font-size: $font-size-lg;
    }

    .cr-input:nth-of-type(1) {
      margin-right: 0.25rem;
    }
    .cr-input:nth-of-type(2) {
      margin-left: 0.25rem;
    }

    .cr-input {
      display: inline-block;
      min-width: 120px;
      max-width: 120px;
    }
  }

  .--item-double-text-wrapper {
    .err-message {
      position: relative;
      display: block;
      margin: 0.5rem 0 0 0;
      padding: 0;
      color: $color-red-600;
      animation-name: top-to-bottom;
      animation-duration: 0.367s;
      animation-timing-function: cubic-bezier(0.1, 0.9, 0.2, 1);
      animation-fill-mode: both;
    }
  }

  .--item-lab-test-normal-range-icon {
    display: inline-block;
    padding-left: 0.4rem;
    cursor: context-menu;

    .--item-lab-test-normal-range-icon--cover {
      position: absolute;
      top: 10px;
      right: 10px;
      bottom: 10px;
      left: 10px;
    }
  }

  .item.--appearance-separated {
    overflow: hidden;
    padding: 0;
  }

  .--wrapped-text {
    position: relative;
    box-sizing: border-box;
    width: 100%;
    padding-top: 5px;
    padding-right: 20px;
    padding-bottom: 4px;
    padding-left: 8px;
    border: 1px solid #ccc;
    line-height: 150%;
    text-align: left;
    word-break: break-all;

    &.--not-selected {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      word-break: keep-all;
    }

    &:after {
      content: "expand_more";
      position: absolute;
      top: 50%;
      right: 3px;
      display: inline-block;
      margin-top: -9px;
      font-size: $font-size-xl;
      font-family: "Material Icons Outlined";
    }

    &:hover {
      border-color: #aaa;
    }
  }

  th.--row-cols {
    padding: 0;
  }

  .--item-label-tooltip-icon {
    position: relative;
    display: inline-block;
    width: 15px;
    height: 18px;
    margin-left: 5px;
    cursor: help;

    i {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 50px;
      height: 50px;
      margin-top: -20px;
      margin-left: -25px;
      color: $color-primary;
      font-weight: bold;
      font-size: $font-size-lg;
      line-height: 50px;
      text-align: center;
    }
  }
  .--item-uival-tooltip-icon {
    position: relative;
    display: inline;
    margin-left: 13px;
    cursor: help;

    i {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 50px;
      height: 50px;
      margin-top: -25px;
      margin-left: -25px;
      color: $color-primary;
      font-weight: bold;
      font-size: $font-size-lg;
      line-height: 50px;
      text-align: center;
    }
  }

  .item-table.attr--sticky-scrollbar {
    .item-table-wrapper {
      overflow: auto;

      &::-webkit-scrollbar {
        display: none;
        width: 0;
        height: 0;
      }

      &::-webkit-scrollbar-track {
        display: none;
        background-color: #fff;
      }

      &::-webkit-scrollbar-thumb {
        display: none;
        background-color: #fff;
      }
    }

    .app-study-crf-items {
      width: 100%;
      border-spacing: 0;
      border-collapse: separate;

      thead {
        th {
          border-top: 0px;
          border-right: 1px solid #ddd;
          border-bottom: 1px solid #ddd;
          border-left: 0px;
        }

        tr:first-child th {
          border-top: 1px solid #ddd;
        }
      }

      td {
        border-top: 0px;
        border-right: 1px solid #ddd;
        border-bottom: 1px solid #ddd;
        border-left: 0px;
      }

      tbody > tr:last-child td {
        border-bottom: 1px solid #ddd !important;
      }

      &.without-item-status th:nth-last-child(2),
      &.without-item-status td:nth-last-child(2),
      &.with-item-status th:nth-last-child(3),
      &.with-item-status td:nth-last-child(3) {
        border-right: 0px;
      }

      .misc:nth-last-child(2) {
        position: sticky;
        right: 85px;
        z-index: 999;
        border-right: 0px;
        border-left: 1px solid #ddd;
      }

      .misc:last-child,
      .item-status:last-child {
        position: sticky;
        right: 0;
        z-index: 999;
        border-left: 1px solid #ddd;
      }
    }

    .item-table--sticky-scrollbar {
      position: sticky;
      position: -webkit-sticky;
      bottom: 0;
      z-index: 9999;

      display: block;
      width: 100%;
      height: 8px;
      background: #fff;
      > div {
        position: relative;
        width: 100%;
        height: 100%;

        > span {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          border-radius: 6px;
          background: #cfcfcf;
          cursor: pointer;
        }
      }
    }
  }

  .item-appendable-table.attr--sticky-scrollbar {
    .item-foldable-table-wrapper,
    .item-appendable-table-wrapper {
      overflow: auto;

      &::-webkit-scrollbar {
        display: none;
        width: 0;
        height: 0;
      }

      &::-webkit-scrollbar-track {
        display: none;
        background-color: #fff;
      }

      &::-webkit-scrollbar-thumb {
        display: none;
        background-color: #fff;
      }
    }

    .app-study-crf-items {
      width: 100%;
      border-spacing: 0;
      border-collapse: separate;

      thead {
        th {
          border-top: 0px;
          border-right: 1px solid #ddd;
          border-bottom: 1px solid #ddd;
          border-left: 0px;
        }

        tr:first-child th {
          border-top: 1px solid #ddd;
        }
      }

      td {
        border-top: 0px;
        border-right: 1px solid #ddd;
        border-bottom: 1px solid #ddd;
        border-left: 0px;
      }

      tbody > tr:last-child td {
        border-bottom: 1px solid #ddd !important;
      }

      &.without-item-status th:nth-last-child(2),
      &.without-item-status tr.--first td:nth-last-child(2),
      &.without-item-status tr:not(.--first) td:nth-last-child(1),
      &.without-item-status tr.query td:nth-last-child(2),
      &.with-item-status th:nth-last-child(3),
      &.with-item-status tr.--first td:nth-last-child(3),
      &.with-item-status tr:not(.--first) td:nth-last-child(1),
      &.with-item-status tr.query td:nth-last-child(3) {
        border-right: 0px;
      }

      .misc:nth-last-child(2) {
        position: sticky;
        right: 85px;
        z-index: 999;
        border-right: 0px;
        border-left: 1px solid #ddd;
      }

      .misc:last-child,
      .item-status:last-child {
        position: sticky;
        right: 0;
        z-index: 999;
        border-left: 1px solid #ddd;
      }
    }

    .item-foldable-table--sticky-scrollbar,
    .item-appendable-table--sticky-scrollbar {
      position: sticky;
      position: -webkit-sticky;
      bottom: 0;
      z-index: 9999;

      display: block;
      width: 100%;
      height: 8px;
      background: #fff;
      > div {
        position: relative;
        width: 100%;
        height: 100%;

        > span {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          border-radius: 6px;
          background: #cfcfcf;
          cursor: pointer;
        }
      }
    }
  }

  .type--table-row-cols {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;

    .--row {
      position: relative;
      display: flex;
      flex-basis: 100%;
      flex-direction: row;
      overflow: hidden;

      &:before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        height: 1px;
        background: #ddd;
      }

      &:first-child {
        &:before {
          display: none;
        }
      }
    }

    .--col {
      position: relative;
      overflow: hidden;
      padding: 0.7rem 0.65rem;

      &:before {
        content: "";
        position: absolute;
        top: -200%;
        bottom: -200%;
        left: 0;
        width: 1px;
        background: #ddd;
      }

      &:first-child {
        &:before {
          display: none;
        }
      }
    }
  }

  .type--constant {
    position: relative;

    &.--align-left {
      div div {
        justify-content: left;
        text-align: left;
      }
    }
  }

  .type--description {
    $--desc-height: 18px;

    padding: 0.75rem 0.65rem;

    &:not(.--box) {
      padding: 0.65rem;
      border: none;
      background: none;
    }

    p {
      margin: 0.38rem 0 0 0;
      line-height: $--desc-height;
      word-break: keep-all;

      &:first-child {
        margin-top: 0;
      }
    }

    .--description {
      margin-bottom: 0.65rem;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .attr--tooltip-icon {
      position: relative;
      display: inline-block;
      width: 15px;
      height: $--desc-height;
      margin-left: 5px;
      cursor: help;

      i {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 50px;
        height: 50px;
        margin-top: -20px;
        margin-left: -25px;
        color: $color-primary;
        font-weight: bold;
        font-size: $font-size-lg;
        line-height: 50px;
        text-align: center;
      }
    }
  }

  .type--text {
    .--double {
      .ms-TextField-fieldGroup {
        max-width: calc(#{$-text-default-size} * 1.5);
      }
    }

    .--full {
      .ms-TextField-fieldGroup {
        max-width: 90%;
      }
    }
  }

  .item--text {
    &.--full {
      width: 100%;
    }
  }

  .item-format-text,
  .item-format-char,
  .item-format-natural-number,
  .item-format-decimal {
    &.--full {
      width: 100%;
    }
    &.--double {
      width: 100%;
      max-width: 270px;
    }
  }

  .item-format-text {
    .ms-TextField-fieldGroup {
      textarea {
        resize: none;
      }
    }
  }

  .--with-desc {
    display: flex;
    justify-content: left !important;

    &.--inline {
      flex-direction: row !important;
      align-items: center;

      > div {
        flex-basis: auto !important;
      }
      > div:not(:first-child) {
        margin-left: 5px;
      }
    }

    &.--block {
      flex-direction: column !important;
      > div:not(:first-child) {
        justify-content: left !important;
        margin-top: 5px;
      }
    }

    .--desc {
      font-size: $font-size-xs;
    }
  }

  .item--wrapper {
    overflow: hidden;
  }

  .type--sys-val {
    &.--removable {
      display: flex;
    }
  }

  .--with-children.--inline .items--wrapper.--loading {
    position: relative;
    display: inline-block;

    &:after {
      content: "";
      position: absolute;
      top: 50%;
      right: -17px;
      box-sizing: border-box;
      width: 14px;
      height: 14px;
      margin-top: -7px;
      margin-right: 5px;
      border: 2px solid #888;
      border-left-color: transparent;
      border-radius: 50%;
      background: transparent;
      opacity: 0;
      opacity: 1;
      transition: all ease-in-out 0.23s;
      animation: rotateOpacity 0.95s ease-in-out infinite 0.1s;
    }
  }

  .--children-wrapper {
    &.--horizontal {
      display: flex;
      gap: 15px;
    }
  }

  .--children-wrapper > *:not(:first-child) {
    margin-top: 5px;
  }

  .--with-children.--block .items--wrapper,
  .--alone .items--wrapper {
    &.--loading .item--wrapper {
      > .cr-calendar-input .ms-TextField-fieldGroup,
      > .type--single-select-dropdown,
      > .item-format-text .ms-TextField-fieldGroup,
      > .type--dictionary {
        &:after {
          content: "";
          position: absolute;
          top: 50%;
          right: 0px;
          box-sizing: border-box;
          width: 14px;
          height: 14px;
          margin-top: -7px;
          margin-right: 5px;
          border: 2px solid #888;
          border-left-color: transparent;
          border-radius: 50%;
          background: transparent;
          opacity: 0;
          opacity: 1;
          transition: all ease-in-out 0.23s;
          animation: rotateOpacity 0.95s ease-in-out infinite 0.1s;
        }
      }
    }

    .cr-checkboxes {
      > .--loading .ms-Checkbox-label {
        position: relative;
        flex-basis: auto;

        &:after {
          content: "";
          position: absolute;
          top: 50%;
          left: 2px;
          box-sizing: border-box;
          width: 14px;
          height: 14px;
          margin-top: -7px;
          margin-right: 5px;
          border: 2px solid #888;
          border-left-color: transparent;
          border-radius: 50%;
          background: transparent;
          opacity: 0;
          opacity: 1;
          transition: all ease-in-out 0.23s;
          animation: rotateOpacity 0.95s ease-in-out infinite 0.1s;
        }
      }
    }
  }

  .--with-children {
    .item--wrapper {
      display: flex;
      flex-direction: row !important;
      align-items: center;
      justify-content: center !important;

      > div {
        flex-basis: auto !important;
      }

      > div:not(:first-child) {
        margin-left: 5px;
      }
    }

    .align--left {
      align-items: center;
      justify-content: flex-start !important;
    }

    .align--right {
      align-items: center;
      justify-content: flex-end !important;
    }

    &.--block {
      .item--wrapper {
        flex-direction: column !important;
        align-items: baseline;

        > div:not(:first-child) {
          margin-top: 5px;
          margin-left: 0;
        }
      }
    }
  }

  .--error {
    &.--slide {
      position: relative;
      animation-name: top-to-bottom;
      animation-duration: 0.367s;
      animation-timing-function: cubic-bezier(0.1, 0.9, 0.2, 1);
      animation-fill-mode: both;
    }

    .ms-TextField-fieldGroup {
      border-color: $color-red-600;
    }

    .error--message {
      display: block;
      margin-top: 0.3rem;
      color: $color-red-600;
      font-size: $font-size-sm;
    }
  }

  .cr-checkboxes {
    .cr-input {
      margin-top: 0.3rem;
    }

    .item-check--with-text {
      display: flex;
      flex-direction: column;

      .ms-Checkbox-label {
        align-items: baseline;
        padding-left: 0;
      }

      em {
        margin-top: 0.2rem;
        margin-left: 1rem;
        font-style: normal;
        font-size: $font-size-base;
        pointer-events: none;
      }
    }
  }

  .type--table {
    .--with-children.--block .item--wrapper {
      align-items: center !important;
      justify-content: center !important;
    }
  }

  .foldable-section-table,
  .type--foldable-table,
  .type--appendable-table,
  .type--table,
  .type--horizontal-group {
    padding: 0;
    background: none;

    > table,
    > .item-table > .item-table-wrapper > table,
    > .item-foldable-table > .item-foldable-table-wrapper > table,
    > .item-appendable-table > .item-appendable-table-wrapper > table {
      tr:first-child th {
        border-top: none;

        &:first-child {
          border-left: none;
        }

        &:last-child {
          border-right: none;
        }
      }

      &.--no-header {
        tr:first-child td {
          border-top: none;

          &:first-child {
            border-left: none;
          }

          &:last-child {
            border-right: none;
          }
        }
      }

      th {
        overflow: hidden;
        text-align: center;
        word-break: break-all;
      }

      .query {
        td {
          text-align: left;
        }
      }

      tr:last-child td {
        border-bottom: none;
      }

      th:first-child,
      td:first-child {
        border-left: none;
      }

      th:last-child,
      td:last-child {
        border-right: none;
      }

      .--strike {
        td {
          color: #ccc;
          text-decoration: line-through;
        }

        td.item-status {
          text-decoration: none !important;
        }
      }

      .item {
        .item--wrapper {
          overflow-wrap: anywhere;
        }
      }

      .ms-TextField {
        .ms-TextField-description {
          text-align: center;
        }
      }

      .cr-checkboxes {
        display: flex;
        align-items: flex-start;
        justify-content: center;

        .ms-Checkbox {
          display: flex;
        }
      }

      td > div,
      td > div > div,
      td > div > div > .item--single-select,
      td > div > div .ms-TextField,
      td > div > div .ms-TextField-wrapper,
      td > div > div .ms-TextField-fieldGroup {
        display: flex;
        flex-basis: 100%;
        justify-content: center;
      }

      .type--text {
        div {
          display: flex;
          justify-content: center;
        }

        .ms-TextField {
          display: flex;
          flex-direction: column;
        }
      }

      .cr-calendar-input {
        .ms-TextField-fieldGroup {
          max-width: 150px;
        }
      }
    }
    > .item-foldable-table > .item-foldable-table-wrapper > table {
      .item-foldable-table-summary-row {
        td > div,
        td > div > div {
          display: flex;
          flex-basis: 100%;
          justify-content: center;
        }
      }
      td > div,
      td > div > div,
      td > div > div > .item--single-select,
      td > div > div .ms-TextField,
      td > div > div .ms-TextField-wrapper,
      .cr-checkboxes,
      td > div > div .ms-TextField-fieldGroup {
        display: flex;
        flex-basis: 100%;
        justify-content: flex-start;
      }
      .type--text {
        div {
          display: flex;
          justify-content: flex-start;
        }

        .ms-TextField {
          display: flex;
          flex-direction: column;
        }
      }
    }

    .cr-clearable-radio-buttons {
      align-items: left;
    }
  }

  .type--horizontal-group {
    > table {
      td.item > div,
      td.item > div > div {
        display: flex;
        flex-basis: 100%;
        flex-direction: column;
        justify-content: center;

        .ms-TextField-wrapper {
          justify-content: flex-start;
        }
      }

      th,
      td {
        text-align: left;

        .cr-input {
          div[role="alert"] {
            justify-content: left;
          }
        }

        .ms-TextField {
          .ms-TextField-description {
            text-align: left;
          }
        }
      }
    }
  }
}

.--theme-light-dark {
  .app-study-crf-items {
    .app-study-crf-group-header {
      $--background-color: #404040;
      $--border-color: #5f5f5f;

      > th {
        position: relative;
        border-color: $--border-color;
        background: $--background-color;
        color: #eee;

        .--row,
        .--col {
          &:before {
            background-color: $--border-color;
          }
        }

        &:before {
          content: "";
          position: absolute;
          top: -1px;
          right: -1px;
          bottom: -1px;
          left: -1px;
          display: block;
          border: 2px solid $--background-color;
        }

        &:after {
          content: "";
          position: absolute;
          top: 0;
          bottom: 0;
          left: -1px;
          width: 1px;
          background: $--border-color;
        }

        &:first-child {
          &:after {
            display: none;
          }
        }
      }
    }
  }
}

/* Study > Report */
.app-study-report {
  .app-study-subject-crf {
    .cr-grid {
      .cr-grid-item {
        max-width: 100%;
      }
    }
  }
}

.mv-item-description-tooltip-callout {
  max-width: 500px;
  padding: 0.7rem;

  p {
    position: relative;
    margin: 0.38rem 0 0 0;
    padding-left: 13px;
    line-height: 18px;
    word-break: keep-all;

    &:first-child {
      margin-top: 0;
    }

    &:before {
      content: "-";
      position: absolute;
      top: 0;
      left: 0;
      display: inline-block;
      margin-right: 5px;
      line-height: 16px;
    }
  }

  &.attr--tooltip-text-1 {
    p {
      padding-left: 0;

      &:before {
        display: none;
      }
    }
  }
}

.cr-description-tooltip-callout {
  max-width: 500px;
  padding: 0.7rem;
  background-color: white;

  p {
    position: relative;
    margin: 0.38rem 0 0 0;
    padding-left: 13px;
    line-height: 18px;
    word-break: keep-all;

    &:first-child {
      margin-top: 0;
    }

    &:before {
      content: "•";
      position: absolute;
      top: 0;
      left: 0;
      display: inline-block;
      margin-right: 5px;
      line-height: 16px;
    }
  }

  &.attr--tooltip-text-1 {
    p {
      padding-left: 0;

      &:before {
        display: none;
      }
    }
  }

  .cr-description-tooltip-scroll {
    overflow: auto;
    max-height: 280px;
  }
}

.mv-chart-centered {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  background: #f9f9f9;
}

.mv-related-text-callout {
  min-width: 550px;
  max-width: 1200px;
  padding: 1.2rem;

  &.static-height {
    max-height: 250px;

    thead {
      position: sticky;
      top: 0px;
    }
  }
}

.callout--signature {
  min-width: 400px;
  max-width: 100%;
}

.mv-crf-table-inline {
  width: 100%;
  border-collapse: collapse;

  th,
  td {
    padding: 0.35rem 0.5rem;
    border: 1px solid #ccc;
  }

  th {
    padding: 0.5rem 0.5rem;
    background-color: #efefef;
  }

  td {
    font-size: $font-size-base;

    .link {
      color: $app-highlight-color;
      cursor: pointer;
    }
  }
}

.mv-item-single-select-dropdown-callout {
  min-width: 150px;
  max-width: 450px;
  padding: 0;
  border-radius: 2px;

  .ms-Callout-main {
    padding: 0;

    &::-webkit-scrollbar {
      width: 8px;
      height: 8px;
    }

    &::-webkit-scrollbar-track {
      background-color: #e4e4e4;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #999;

      &:hover {
        background-color: #777;
      }
    }

    .item--single-select {
      padding: 1.2rem;
    }
  }

  .cr-radio-buttons {
    .ms-ChoiceField-wrapper {
      width: 100%;
    }

    .cr-radio-button {
      display: flex;
      flex-basis: 100%;
      flex-direction: column;
      align-items: flex-start;

      .--type-text {
        display: flex;
        flex-basis: 100%;
        margin-left: 1.5rem;

        .cr-input {
          width: 100%;
          min-width: 120px;
        }

        &.--double {
          .cr-input {
            min-width: 220px;
          }
        }

        &.--full {
          .cr-input {
            min-width: 320px;
          }
        }

        .cr-button {
          min-width: 50px;
          max-width: 80px;
          margin-left: 3px;
          padding: 0 8px;
        }
      }
    }
  }

  .cr-clearable-radio-buttons {
    .cr-clearable-radio-button {
      display: flex;
      flex-basis: 100%;
      flex-direction: column;
      align-items: flex-start;

      .--type-text {
        display: flex;
        flex-basis: 100%;

        .cr-input {
          width: 100%;
          min-width: 120px;
        }

        &.--double {
          .cr-input {
            min-width: 220px;
          }
        }

        &.--full {
          .cr-input {
            min-width: 320px;
          }
        }

        .cr-button {
          min-width: 50px;
          max-width: 80px;
          margin-left: 3px;
          padding: 0 8px;
        }
      }
    }
  }
}

.mv-item-dictionary-callout {
  width: 100%;
  max-width: 650px;

  .ms-Callout-main {
    overflow: auto;
    width: 100%;
    min-width: 450px;

    .--wrap {
      min-width: 650px;
      max-width: 650px;
      padding: 1.2rem;
    }
  }

  .item-dictionary--result {
    .empty {
      font-size: $font-size-sm;
      text-align: center;
    }
  }

  &.--meddra {
  }

  &.--atc_index {
  }

  &.--whodd_b3 {
    width: 100%;
    max-width: 1000px;

    .ms-Callout-main {
      width: 100%;

      .--wrap {
        min-width: 1000px;
        max-width: 1000px;

        .input-box {
          display: flex;
          max-width: 300px;
          margin-left: 0.5rem;

          .ms-TextField-wrapper {
            width: 130px;
            margin-right: 3px;
          }
        }
      }
    }
  }

  &.--whodd_c3 {
    width: 100%;
    max-width: 1000px;

    .ms-Callout-main {
      width: 100%;

      .--wrap {
        min-width: 1000px;
        max-width: 1000px;

        .input-box {
          display: flex;
          max-width: 300px;
          margin-left: 0.5rem;

          .ms-TextField-wrapper {
            width: 130px;
            margin-right: 3px;
          }
        }
      }
    }
  }

  .search {
    $-spacing: 1px;
    display: flex;
    flex-basis: 100%;
    justify-content: space-between;
    margin-right: calc(#{$-spacing} * -1);
    margin-left: calc(#{$-spacing} * -1);

    .field {
      display: flex;
      flex-basis: 100%;
      max-width: 840px;

      > * {
        margin: 0 calc(#{$-spacing} * 1);
      }

      .ms-TextField {
        display: flex;
        flex-basis: 100%;

        .ms-TextField-wrapper {
          width: 100%;
        }
      }

      .cr-dropdown {
        min-width: 120px;

        &.--medium-width {
          width: 150px;
        }

        &.--large-width {
          width: 170px;
        }
      }
    }

    .version {
      display: flex;
      justify-content: flex-end;
      min-width: 140px;
      padding: 0 0.3rem;
      font-weight: bold;
      line-height: 30px;
      text-align: left;
    }
  }

  .result {
    overflow-y: auto;
    max-height: 300px;
    margin-top: 0.5rem;

    table {
      width: 100%;
      border-collapse: collapse;
      table-layout: fixed;

      th,
      td {
        padding: 5px 8px;
        border: 1px solid #ccc;
      }

      td {
        font-size: $font-size-sm;
        word-break: break-all;

        sub {
          vertical-align: auto;
          color: lighten(#000, 40%);
          font-size: $font-size-xs;
        }

        .link {
          color: $app-highlight-color;
          cursor: pointer;

          sub {
            color: darken($app-highlight-color, 15%);
          }
        }
      }
    }
  }

  &.--coding-search {
    max-width: initial;

    .field {
      align-items: flex-end;
    }
  }
}

.mv-callout-lab-test-normal-range {
  padding: 0;

  .ms-DetailsRow-cell {
    white-space: normal;
    word-break: keep-all;
  }

  .cr-table {
    div,
    td {
      align-items: center;
      justify-content: center;
      text-align: center;
    }
  }
}

.mv-callout-mw {
  max-width: calc(100% - 16px);

  .ms-Callout-main {
  }
}

.mv-item-audit-trail-callout {
  .ms-Callout-main {
    height: 450px;
    max-height: 700px !important;

    .--wrap {
      padding: 1.2rem;
    }
  }
}

.mv-item-query-issuer-info-callout {
  > div {
    padding: 10px;
    color: #3f3f3f;
  }
}

.mv-uat-comment-callout {
  .--wrap {
    padding: 1.2rem;
  }
}

.app-study-coding {
  .--crf-item-label {
    th {
      word-break: break-all;
    }
  }
  .--crf-item-value {
    td {
      word-break: break-all;
    }
  }

  .--coding-title {
    border-right: 0px;

    &.highlight {
      background-color: yellow;
    }

    &.noHighlight {
      background-color: initial;
      transition: 1s;
    }
  }
  .--dictionary-search {
    border-left: 0px;

    .--search {
      &.--whodd {
        display: flex !important;
        flex-wrap: wrap;
        margin: 0 1rem 0 0 !important;

        .--search-conditions {
          $-spacing: 1px;
          display: flex !important;

          .cr-dropdown {
            min-width: 120px;
            margin-right: 2px;
            margin-bottom: 2px;

            &.--medium-width {
              width: 150px;
            }

            &.--large-width {
              width: 170px;
            }
          }
        }

        .--additional-input {
          display: flex;
          margin-left: 0.5rem;

          .ms-TextField-wrapper {
            margin-right: 3px;
          }
        }
      }

      .--search-input {
        display: inline-block;

        .ms-TextField-wrapper {
          margin-right: 3px;
        }
      }

      .--search-btn {
        display: inline-block !important;
      }
    }

    .version {
      float: right;
      padding: 0 0.8rem;
      font-weight: bold;
      line-height: 30px;
      text-align: left;
    }

    .--primary-soc {
      margin-top: 0.5rem !important;
    }

    .--meddra-checkbox {
      margin: 0.5rem 1rem 0 0 !important;
    }

    &.highlight {
      background-color: yellow;
    }

    &.noHighlight {
      background-color: initial;
      transition: 1s;
    }
  }
  .--meddra-list {
    overflow: scroll;
    width: 100%;
    height: 300px;

    .--code {
      font-size: $font-size-sm;

      &.--fold {
        margin-bottom: 0.45rem;
      }

      &.--unfold {
        margin-bottom: 1rem;
      }

      .--layer {
        letter-spacing: 0.02rem;
      }

      .--llt {
        > span {
          cursor: pointer;
        }
        .--pt {
          margin: 0.4rem 0 0 1rem;
          > span {
            cursor: pointer;
          }
          .--hlt {
            margin: 0.4rem 0 0 1rem;
            > span {
              cursor: pointer;
            }
            .--hlgt {
              margin: 0.4rem 0 0 1rem;
              > span {
                cursor: pointer;
              }
              .--soc {
                margin: 0.4rem 0 0 1rem;
                > span {
                  color: $app-highlight-color;
                  cursor: pointer;
                }
              }
            }
          }
        }
      }
    }
  }

  .--atc-list {
    overflow: scroll;
    height: 300px;

    > table {
      width: 100%;
      border-collapse: collapse;

      .--code {
        height: 10px;
        font-size: $font-size-sm;
        > span {
          color: $app-highlight-color;
          cursor: pointer;
        }
      }
    }
  }

  .--whodd-list {
    overflow: scroll;
    height: 300px;

    > table {
      width: 100%;
      border-collapse: collapse;

      .--code {
        height: 10px;
        font-size: $font-size-sm;
        .link {
          color: $app-highlight-color;
          cursor: pointer;
        }
      }

      .empty {
        font-size: $font-size-sm;
        text-align: center;
      }
    }
  }
}

.mv-item-misc-audit-trail-callout {
}

.mv-item-audit-trail {
  .ms-DetailsRow-cell {
    word-break: keep-all;
  }
}

.fast-coding-dialog {
  .ms-Dialog-main {
    width: 800px;
    min-width: 320px;
    max-width: 800px;

    .--attribute {
      margin-bottom: 1rem;
      table {
        width: 100%;
        border-collapse: collapse;

        th,
        td {
          padding: 0.7rem 0.65rem;
          border: 1px solid #ccc;
          background: #fff;
          text-align: left;

          &.label {
            em {
              margin-left: 0.3rem;
              color: $color-red-600;
              font-style: normal;
            }
          }
        }
      }
    }

    .--dict-code-search {
      margin-bottom: 0.4rem;
      &.--flex {
        display: flex;
      }

      &.--block {
        display: block;
      }
      .--select {
        display: inline-block;
      }

      .--input {
        display: inline-block;
      }

      .--btn {
        margin-left: 0.5rem;
        border-color: #ccc;
      }

      .--version {
        display: flex;
        justify-content: flex-end;
        margin-left: auto;
        padding: 0 0.3rem;
        font-weight: bold;
        line-height: 35px;
      }

      .--search {
        display: flex !important;
        flex-wrap: wrap;
        margin: 0 1rem 0 0 !important;

        .--search-conditions {
          $-spacing: 1px;
          display: flex !important;

          .cr-dropdown {
            min-width: 120px;
            margin-right: 2px;
            margin-bottom: 2px;

            &.--medium-width {
              width: 150px;
            }

            &.--large-width {
              width: 170px;
            }
          }
        }

        .--additional-input {
          display: flex;
          margin-left: 0.5rem;

          .ms-TextField-wrapper {
            margin-right: 3px;
          }
        }

        .--search-btn {
          display: inline-block !important;
        }
      }
    }

    .--primary-soc {
      margin-top: 0.5rem !important;
    }

    .--meddra-checkbox {
      margin: 0.5rem 1rem 0 0 !important;
    }

    .--code {
      display: flex;
      align-items: center;
      font-size: $font-size-sm;

      > span {
        color: $app-highlight-color;
      }
    }

    .--code-list {
      overflow-y: auto;
      max-height: 250px;
      margin-top: 0.4rem;
      margin-bottom: 1rem;

      table {
        width: 100%;
        border-collapse: collapse;

        th {
          text-align: center;
        }

        th,
        td {
          padding: 5px 8px;
          border: 1px solid #ccc;
        }

        td {
          font-size: $font-size-xs;
          line-height: 0.8rem;
          word-break: keep-all;
          .link {
            color: $app-highlight-color;
            cursor: pointer;
          }
        }
      }
    }

    .--note {
      margin: 1rem 0 0 0;
      padding: 0;
      color: $color-primary;
      list-style-position: inside;
      font-size: $font-size-sm;
    }

    .empty {
      font-size: $font-size-sm;
      text-align: center;
    }
  }

  &.--whodd_b3 {
    .ms-Dialog-main {
      width: 95%;
      min-width: 400px;
      max-width: 1100px;
    }
  }

  &.--whodd_c3 {
    .ms-Dialog-main {
      width: 95%;
      min-width: 400px;
      max-width: 1800px;
    }
  }
}

.app-study-lock,
.app-study-close {
  width: 100%;
  .--study-summary {
    .title {
      margin-bottom: 1rem;
      font-weight: bold;
      font-size: $font-size-lg;
    }
    table {
      width: 100%;
      border-collapse: collapse;

      td {
        padding: 11px;
        border-top: 1px solid #edebe9;
        border-bottom: 1px solid #edebe9;
        font-size: $font-size-sm;
        line-height: 1rem;
        word-break: keep-all;
      }

      td.label {
        font-weight: bold;
      }
    }
  }

  .--log {
    height: 300px;
    max-height: 400px;
    margin-bottom: 50px;

    .cr-section-inner {
      overflow: scroll;
      height: 300px;
    }

    .title {
      margin-bottom: 1rem;
      font-weight: bold;
      font-size: $font-size-lg;
    }

    table {
      width: 100%;
      border-collapse: collapse;

      th {
        padding-top: 11px;
        padding-bottom: 8px;
        border-bottom: 1px solid #edebe9;
        font-weight: bold;
      }

      td {
        height: 8px;
        padding: 11px;
        border-top: 1px solid #edebe9;
        border-bottom: 1px solid #edebe9;
        font-size: $font-size-sm;
        line-height: 1rem;
        text-align: center;
        word-break: keep-all;
      }

      td.--empty {
        height: 200px;
        border: none;
      }
    }
  }
}

.mv-callout-data-modify {
  width: 100%;
  max-width: 250px;
  padding: 1rem;

  &.uat-close-callout {
    max-width: 300px;

    .uat-close-callout-buttons {
      display: block;
      padding-top: 10px;
      text-align: right;
    }
  }
}

.mv-study-dw-audit-trail {
  label {
    &.title {
      display: block;
      padding: 5px 0px;
      color: rgb(50, 49, 48);
      font-weight: 600;
      font-size: 0.82rem;
      font-family: Nunito, "Noto Sans KR", sans-serif;
      line-height: 13px;
    }
  }

  div {
    &.date-container {
      display: flex;
      align-items: center;

      .cr-checkbox {
        margin-right: 1rem;
      }
    }
  }

  .--type-range {
    display: flex;
    align-items: center;

    .cr-calendar-input {
      width: 100%;
    }

    .--delimiter {
      margin: 0 0.25rem;
    }
  }
}

.mv-callout-component {
  width: 100%;
  max-width: 250px;
  padding: 1rem;
}

.cr-button {
  &.--force-disable-button,
  &.--sdv-button,
  &.--freeze-button,
  &.--sign-button,
  &.--lock-button {
    overflow: hidden;

    &.--cancel:before {
      content: "";
      position: absolute;
      top: -10px;
      left: -10px;
      width: 18px;
      height: 18px;
      background: #fff;
      transform: rotate(45deg);
    }
  }

  &.--force-disable-button {
    border-color: $color-force-disable !important;
    background-color: $color-force-disable;
    color: #fff;

    &:hover {
      background-color: $color-force-disable-hover;
    }
  }

  &.--sdv-button {
    border-color: $color-sdv !important;
    background-color: $color-sdv;
    color: #fff;

    &:hover {
      background-color: $color-sdv-hover;
    }
  }

  &.--freeze-button {
    border-color: $color-freeze !important;
    background-color: $color-freeze;
    color: #fff;

    &:hover {
      background-color: $color-freeze-hover;
    }
  }

  &.--sign-button {
    border-color: $color-sign !important;
    background-color: $color-sign;
    color: #fff;

    &:hover {
      background-color: $color-sign-hover;
    }
  }

  &.--lock-button {
    border-color: $color-lock !important;
    background-color: $color-lock;
    color: #fff;

    &:hover {
      background-color: $color-lock-hover;
    }
  }
}

.app-uat-steps {
  margin: 2rem 0;

  .cr-step {
    max-width: 250px;
  }
}

.app-builder-add-crf-payload {
  margin: 30px 0;
}

.app-builder-add-crf-payload-step-content-wrapper {
  position: relative;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  width: 100%;
  min-height: 550px;
  max-height: 550px;
  border: $app-content-box-border-darken;
  border-radius: $app-content-box-radius;
  background: $app-content-background-lighten;
  box-shadow: $app-content-box-shadow;

  @media (max-height: 1040px) {
    min-height: 450px;
    max-height: 450px;
  }

  @media (max-height: 940px) {
    min-height: 400px;
    max-height: 400px;
  }

  @media (max-height: 840px) {
    min-height: 350px;
    max-height: 350px;
  }

  @media (max-height: 740px) {
    min-height: 300px;
    max-height: 300px;
  }

  @media (max-height: 640px) {
    min-height: 250px;
    max-height: 250px;
  }
}

.app-builder-add-crf-payload-step-content {
  min-width: 650px;
  padding: calc(#{$app-content-padding-narrow} * 1.2);

  .app-builder-add-crf-payload-error-msg {
    position: sticky;
    top: 5px;
    right: 0;
    left: 0;
    z-index: 1;
    height: calc(1.3rem + (0.2rem * 2));
    margin: 0 0 1rem 0;
    padding: 0.2rem 0.5rem;
    border-radius: $app-content-box-radius;
    background: $color-red-700;
    color: #fff;
    box-shadow: 0 0 0.3rem rgba(0, 0, 0, 0.3);
    line-height: 1.3rem;

    &:before {
      content: "warning";
      vertical-align: middle;
      margin-right: 4px;
      font-family: "Material Icons Outlined";
    }
  }
}

.app-study-subject-crf-version-container {
  position: absolute;
  top: -0.75rem;
  right: 0;
  display: flex;
  flex-wrap: nowrap;

  dl {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-end;
    margin-right: 1.2rem;
    font-size: $font-size-xs;

    dt {
      margin-right: 0.4rem;
      font-weight: bold;
      font-size: inherit;
    }

    dd {
      margin: 0;
      font-size: inherit;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}

.ip-delivery-request,
.ip-delivery-start-confirm {
  .single-label {
    margin-bottom: -10px;
    font-weight: bold;
    font-size: 0.75rem;
  }
  .required {
    &:after {
      content: " *";
      padding-right: 12px;
      color: rgb(164, 38, 44);
    }
  }

  .app-study-crf-items td {
    text-align: center;
  }
}

.ip-delivery-list,
.ip-return-list {
  .link {
    color: $app-highlight-color;
    text-decoration: underline;
    cursor: pointer;
  }
}

.ip-delivery-list-status-callout,
.ip-return-list-status-callout {
  min-width: 250px;
  padding: 10px;

  .cr-radio-buttons {
    margin-bottom: 5px;
  }

  .cr-button.ms-Button {
    float: right;
    margin-top: 5px;
    margin-left: 3px;
  }
}

.ip-stock-list {
  .ms-DetailsRow-cell[data-automation-key="2"],
  .ms-DetailsRow-cell[data-automation-key="3"],
  .ms-DetailsRow-cell[data-automation-key="4"],
  .ms-DetailsRow-cell[data-automation-key="5"],
  .ms-DetailsRow-cell[data-automation-key="6"],
  .ms-DetailsRow-cell[data-automation-key="7"],
  .ms-DetailsRow-cell[data-automation-key="8"],
  .ms-DetailsRow-cell[data-automation-key="9"] {
    padding: 0px !important;

    .ms-List-cell[data-list-index]:last-of-type {
      .ms-DetailsRow {
        margin-bottom: -1px;
      }
    }
  }

  .ms-DetailsRow-cell[data-automation-key="9"] {
    .cr-col {
      width: 100%;
    }
  }

  .ms-DetailsRow:hover {
    .ms-DetailsRow {
      background-color: rgb(243, 242, 241);
    }
  }
}

.app-study-ipm {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;

  .--label {
    font-weight: bold;
    word-break: break-word;
  }

  .cr-dropdown {
    width: 200px;
  }

  .--ip-code-wrapper {
    display: inline-block;
    margin: 5px 5px 0 0;
    padding: 0 5px;
  }

  .--required {
    &:after {
      content: " *";
      padding-right: 12px;
      color: rgb(164, 38, 44);
    }
  }
}

.app-study-site-crf-layout-warning {
  position: relative;
  bottom: 1rem;
  display: flex;
  color: $color-orange-500;
  font-weight: bold;
  font-size: $font-size-sm;

  .cr-icon {
    margin-right: 0.125rem;
  }

  span {
    display: inline-block;
  }
}

.app-sharing-dialog {
  .ms-Dialog-main {
    width: 100%;

    .app-sharing-dialog--table {
      $-label-width: 200px;
      $-check-width: 85px;
      position: relative;

      overflow: auto;

      table {
        width: 100%;
        border-collapse: collapse;
        table-layout: fixed;

        th,
        td {
          padding: 7px 10px;
          border: 1px solid #ccc;
        }

        th:not(:first-child) {
          position: relative;
          padding-right: 3px;
          padding-left: 3px;

          > div {
            display: -webkit-box;
            overflow: hidden;
            max-height: calc(16px * 2);
            line-height: 16px;
            text-overflow: ellipsis;
            word-break: break-word;
            -webkit-line-clamp: 2;
            line-clamp: 2;
            -webkit-box-orient: vertical;
          }

          &.state--removable:after {
            content: "close";
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            background: rgba(#eee, 0.7);
            font-size: $font-size-3xl;
            font-family: "Material Icons Outlined";
            opacity: 0;
            cursor: pointer;
            transition: opacity ease-in-out 0.15s;
          }

          &:hover {
            &:after {
              opacity: 1;
            }
          }
        }

        th:first-child,
        td:first-child {
          position: sticky;
          left: 0;
          z-index: 1;
          width: $-label-width;
          border-color: transparent;
          border-left: none;
          background: #fff;

          &:before {
            content: "";
            position: absolute;
            top: -1px;
            right: -1px;
            bottom: -1px;
            left: 0;
            border: 1px solid #ccc;
            pointer-events: none;
            user-select: none;
          }
        }

        td:not(:first-child) {
          padding: 0;
        }

        th:not(:first-child),
        td:not(:first-child) {
          width: $-check-width;
          text-align: center;

          .cr-checkbox {
            display: flex;
            align-items: stretch;
            justify-content: center;
          }

          .cr-checkbox div label {
            align-items: center;
            justify-content: center;
          }
        }

        em {
          display: block;
          overflow: hidden;
          color: #777;
          font-style: normal;
          font-size: $font-size-xs;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }

      &.state--empty-user {
        th:first-child {
        }

        th:not(:first-child) {
          width: auto;
        }
      }
    }
  }
}

.app--table-empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 45vh;

  p {
    line-height: 150%;
    text-align: center;
  }

  .cr-icon {
    font-size: 6rem;
  }

  button {
    margin: 0;
    padding: 7px 12px;
    border: 0;
    border-radius: 2px;
    background: $color-primary;
    color: $color-primary-text;
    font-weight: bold;
    cursor: pointer;
    transition: all ease-in-out 0.15s;

    &:hover {
      background-color: darken($color-primary, 3%);
    }

    &:active {
      background-color: darken($color-primary, 5%);
    }
  }
}

.mv-cr-icon-button-subject-contact-dismiss {
  &.--contact-dismiss-button {
    float: right;
  }
}

.mv-callout-subject-contact-input-group {
  .ms-Dialog-contact {
    margin-top: 25px;
    font-size: 0px;
    text-align: right;
    .contact-write-button {
      background-color: $color-primary;
      color: white;
    }
    .contact-save-button {
      margin-right: 4px;
      background-color: $color-primary;
      color: white;
    }
  }
}

.mv-section-contact-color-primary {
  .cr-section-inner {
    padding: 5px 15px;
    box-shadow: none;
    .cr-section-inner-title {
      margin-bottom: 0.75rem;
      color: $color-primary;
      font-size: 20px;
    }
  }
}

.mv-dropdown-label-activate-status-font-size {
  .ms-Dropdown-label {
    height: 23px;
    font-size: $font-size-sm;
    line-height: 13px;
  }
}
