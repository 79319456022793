@import "variables.scss";

.app-drawer-wrapper {
  .app-drawer-content {
    $-padding-top: calc(#{$app-content-box-padding} * 2);
    $-drawer-max-width: 23rem;

    position: fixed;
    top: $app-header-height;

    right: -$-drawer-max-width;
    bottom: 0;
    z-index: 10;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    width: 100%;
    width: 100%;
    max-width: $-drawer-max-width;
    height: 100%;

    padding: 0 $app-content-box-padding $app-content-box-padding $app-content-box-padding;
    background: $app-content-box-background;
    box-shadow: $app-content-box-shadow;
    transition: right ease-in-out 0.2s;

    > .app-drawer-head {
      position: fixed;
      top: 0;
      right: -$-drawer-max-width;
      width: 100%;
      max-width: $-drawer-max-width;
      background-color: $app-content-box-background;
      transition: right ease-in-out 0.2s;
    }

    h1.title {
      margin-top: 0;
      padding-left: $app-content-box-padding;
      font-size: $font-size-2xl;
      line-height: $app-header-height;
      user-select: none;
    }

    .close {
      content: "clear";
      position: absolute;
      top: 0;
      right: 0;
      z-index: 1;
      display: inline-block;
      padding: $app-content-box-padding;
      border: none;
      background: transparent;
      color: $app-text-color;
      font-size: $font-size-xl;
      font-family: "Material Icons Outlined";
      line-height: $font-size-2xl;
      opacity: 1;
      cursor: pointer;
      user-select: none;

      &:hover {
        color: lighten($app-text-color, 5%) !important;
      }

      &:active,
      &:focus {
        color: lighten($app-text-color, 25%) !important;
        outline: none;
      }
    }

    ul {
      li {
        list-style: none;
      }
    }

    .items {
      margin: 0;
      padding: $app-content-box-padding-narrow 0 0 0;

      .item {
      }
    }
  }
}

.app-layout.drawer-open {
  .app-drawer-wrapper {
    .app-drawer-content {
      right: 0;

      > .app-drawer-head {
        right: 0;
      }
    }
  }
}
