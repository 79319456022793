.hb-check {
  $hb-check-item-spacing: 0.4rem;
  $hb-check-item-padding: 0.8rem;

  .hb-check-items {
    position: relative;
    margin: calc(#{$hb-check-item-spacing} * -1);

    .hb-check-item {
      display: flex;
      align-items: center;
      margin: $hb-check-item-spacing;
      padding: $hb-check-item-padding;
      border-radius: 10px;
      background: #fff;
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.03);
      font-size: $font-size-lg;
      line-height: 135%;
      cursor: pointer;
      user-select: none;

      &:hover {
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.13);
      }

      &:before {
        content: "";
        display: inline-block;
        width: 18px;
        min-width: 18px;
        height: 18px;
        margin-top: 18px;
        margin-right: 10px;
        border: 2px solid $color-gray-400;
      }

      .cr-icon {
        color: $color-gray-500;
      }

      &.--selected {
        color: $color-primary;
        font-weight: bold;

        &:before {
          border-color: $color-primary;
        }

        &:after {
          content: "";
          position: absolute;
          top: calc(#{$hb-check-item-padding} + 1px + 22px);
          left: calc(#{$hb-check-item-padding} + 5px);
          width: 12px;
          height: 12px;
          background: $color-primary;
        }

        .cr-icon {
          color: $color-primary;
        }
      }

      &.--disabled {
        background: #f0f0f0;
      }

      .hb-text {
        margin-top: -18px;
      }
    }
  }

  &.--direction-vertical {
  }

  &.--direction-horizontal {
    .hb-check-items {
      display: flex;

      .hb-check-item {
        display: flex;
        flex-basis: 100%;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .hb-check-item-icon {
          display: inline-block;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 50px;
          height: 50px;
          margin-bottom: 10px;
          font-size: $font-size-5xl;
        }
      }
    }
  }

  &.--appearance-bullet {
    .hb-check-item {
      position: relative;
      align-items: flex-start;
      border: 2px solid $color-gray-400;
      line-height: 405%;

      &:hover {
        &:before {
          border-color: $color-gray-500;
        }
      }

      &.--selected {
        border-color: $color-primary;
      }

      .hb-check-item-icon {
        position: absolute;
        right: 0;
        padding-right: $hb-check-item-padding;
        font-size: $font-size-5xl;
      }
    }
  }

  &.--appearance-bordered {
    .hb-check-item {
      border: 2px solid $color-gray-400;

      &:before,
      &:after {
        display: none;
      }

      &:hover {
        border: 2px solid $color-gray-500;
      }

      &.--selected {
        border-color: $color-primary;
      }
    }

    &.--direction-vertical {
      .hb-check-item {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        border-radius: 7px;

        .hb-check-item-icon {
          display: inline-block;
          display: flex;
          align-items: center;
          height: 40px;
          font-size: $font-size-6xl;
        }
      }
    }
  }

  &.--disabled {
    .hb-check-item {
      cursor: default !important;
    }
  }
}
