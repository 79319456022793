.cr-label {
  display: flex;
  padding: 5px 0;
  font-weight: $font-weight-bold;
  font-size: $font-size-sm;
}

.cr-dropdown .ms-Label,
.cr-input .ms-Label {
  height: 23px;
  line-height: 13px;
}