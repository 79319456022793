body {
  font-size: $font-size-base;
  font-family: "Nunito", "Noto Sans KR", sans-serif;
}

a,
button,
span,
p,
input,
em,
div {
  font-family: "Nunito", "Noto Sans KR", sans-serif;
  -webkit-font-smoothing: auto !important;
}

* {
  box-sizing: border-box;
  touch-action: manipulation;
}

a {
  color: $app-link-color;

  &:hover {
    color: $app-link-color-hover;
  }
}

h1 {
  margin: 0.5rem 0;
  font-size: $font-size-4xl;
}

h2 {
  margin: 1rem 0;
  font-size: $font-size-3xl;
}

h3 {
  font-size: $font-size-2xl;
}

h4 {
  font-size: $font-size-xl;
}

input {
  appearance: none;

  &:focus {
    box-shadow: none;
    outline: none;
  }

  &:active {
    box-shadow: none;
    outline: none;
  }
}

button {
  appearance: none;

  &:focus {
    box-shadow: none;
    outline: none;
  }

  &:active {
    box-shadow: none;
    outline: none;
  }
}
