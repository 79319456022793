$color-primary: #259ac5;
$color-primary-light: #007bff;
$color-primary-dark: #1d7b9d;
$color-primary-text: #ffffff;

$app-color-active: $color-primary-dark;
$app-color-focus: $color-primary;
$app-text-color: darken(#55646a, 15%);
$app-link-color: $color-primary-light;
$app-link-color-hover: lighten(mix(#259ac5, blue), 10%);
$app-highlight-color: $color-primary;
$app-highlight-text-color: $color-primary-text;

$app-color-danger: mix(mix(#259ac5, red), red);
$app-color-warning: mix($app-color-danger, orange);
$app-color-normal: lighten(mix(#259ac5, blue), 10%);
$app-color-success: lighten(mix(#259ac5, green), 10%);
$app-color-info: lighten(mix(#259ac5, cyan), 10%);
$app-color-black: $app-text-color;

$app-header-height: 70px;
$app-header-background: #259ac5;
$app-header-color: lighten(#bbe1ea, 10%);
$app-header-border-color: $color-primary-dark;
$app-header-color-highlight: $color-primary-text;
$app-header-shadow: 0 0 0.6rem rgba(0, 0, 0, 0.15);
$app-header-badge-background: #ff5e20;
$app-header-badge-color: #ffffff;

$app-hybrid-header-height: 60px;
$app-hybrid-footer-height: 50px;
$app-hybrid-component-margin: 10px;

$app-footer-height: 45px;
$app-footer-bar-height: 60px;
$app-demo-message-height: 55px;

$app-sidebar-width: 16rem;
$app-sidebar-width-collapsed: 4.8rem;
$app-sidebar-background: #ffffff;
$app-sidebar-background-highlight: #259ac5;
$app-sidebar-color: lighten($app-text-color, 5%);
$app-sidebar-color-highlight: #ffffff;
$app-sidebar-shadow: 0 0 1rem rgba(0, 0, 0, 0.08);
$app-sidebar-transition-duration: 0.23s;
$app-sidebar-logo-color: #259ac5;
$app-sidebar-group-background-highlight: #faf7f5;
$app-sidebar-break-side: $break-2xl;
$app-sidebar-break-icon: $break-lg;

$app-content-background: #faf7f5;
$app-content-background-lighten: lighten(#faf7f5, 1.2%);
$app-content-padding: 1.75rem;
$app-content-padding-narrow: 1rem;
$app-content-box-color: $app-text-color;
$app-content-box-padding: 1.1rem;
$app-content-box-spacing: 0.8rem;
$app-content-box-padding-narrow: 0.6rem;
$app-content-box-background: #ffffff;
$app-content-box-shadow: 0 0.2rem 0.3rem rgba(0, 0, 0, 0.03);
$app-content-box-shadow-hover: 0 0.2rem 0.3rem rgba(0, 0, 0, 0.09);
$app-content-box-border: none;
$app-content-box-border-darken: 1px solid rgba(0, 0, 0, 0.06);
$app-content-box-radius: 0.3rem;

$app-form-border-color: #d9d9d9;
$app-form-border: 1px solid $app-form-border-color;
$app-form-border-radius: 0.3rem;
$app-form-background: #ffffff;
$app-form-color: $app-text-color;
$app-form-field-padding: 0.375rem 0.75rem;

$app-form-dropdown-shadow: 0 0 0.6rem rgba(0, 0, 0, 0.05);
$app-form-dropdown-border: 1px solid rgba(242, 242, 242, 1);
$app-form-dropdown-item-background-hover: #f8f9fa;

$app-table-spacing: 0.5rem;
$app-table-head-padding: 1rem 0.6rem;
$app-table-cell-padding: 0.5rem 1rem;
