.cr-checkbox,
.cr-checkboxes {
  .cr-checkbox-desc {
    display: block;
    margin-top: 3px;
    margin-left: 23px;
    color: lighten(#000, 40%);
    font-size: $font-size-sm;
  }

  .ms-Checkbox-checkbox {
    left: 2px;
    width: 15px;
    height: 15px;
    margin-top: 2px;
    margin-bottom: 2px;
    border-color: #ccc;

    &:hover {
      border-color: #777 !important;
    }
  }

  .ms-Checkbox > input:focus + .ms-Checkbox-label {
    &:before {
      outline: none;
    }

    .ms-Checkbox-checkbox {
      border-color: $color-primary;
      box-shadow: 0 0 0.3rem rgba(0, 0, 0, 0.4);
    }
  }

  .ms-Checkbox {
    &.is-disabled {
      .ms-Checkbox-checkbox {
        background-color: rgb(243, 242, 241);
      }

      &.is-checked {
        .ms-Checkbox-checkbox {
          border-color: #ccc;
          background-color: #ccc;
        }
      }
    }
  }

  .ms-Checkbox:hover {
    .ms-Checkbox-checkbox {
      border-color: #777 !important;
    }
  }

  .ms-Checkbox-text {
    font-size: $font-size-base;
  }

  .ms-Checkbox-label {
    flex-basis: 100%;
  }

  .ms-Checkbox-checkmark {
    font-size: 12px;
  }

  .is-checked {
    .ms-Checkbox-checkbox {
      border-color: $color-primary;
      background: $color-primary;

      &:hover {
        background: darken($color-primary, 10%);
      }
    }
  }
}

.cr-checkboxes {
  .cr-checkboxes-items {
    margin-top: 0.2rem;
  }

  &.--without-label {
    .cr-checkboxes-items {
      margin-top: 0;
    }
  }

  &.--hide-item-label {
    .ms-Checkbox-text {
      display: none;
    }
  }

  &.direction--horizontal {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    > div {
      display: flex;

      &.item-check--with-text {
        flex: 1 0;
        overflow-wrap: break-word;
        padding-bottom: 8px;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        max-width: 150px;

        > div {
          flex: 1;

          &.item-format-text {
            flex: 3;
          }
        }

        & .ms-Checkbox-label {
          justify-content: flex-start;
        }
      }

      .ms-Checkbox-label {
        padding-right: 8px;
        padding-left: 8px;
      }
    }
  }

  &.direction--vertical {
    display: flex;
    flex-direction: column;
    text-align: left;
  }

  &.align--center {
    > div {
      justify-content: center;
    }
  }
}
