.hb-list {
  $hb-list-spacing: 0.5rem;
  $hb-list-item-padding-vertical: 0.9rem;
  $hb-list-item-padding-horizontal: 0.8rem;
  $hb-list-item-column-spacing: 0.2rem;

  margin-top: calc(#{$hb-list-spacing} * -1);
  margin-bottom: calc(#{$hb-list-spacing} * -1);

  .hb-list-title {
    margin-top: calc(#{$app-hybrid-component-margin} * 2);
    padding: 0 calc(#{$hb-list-item-padding-horizontal} / 2);
    font-weight: bold;
    font-size: $font-size-sm;
    text-transform: uppercase;
  }

  .hb-list-items {
    .hb-list-item {
      margin: $hb-list-spacing 0;
      padding: $hb-list-item-padding-vertical $hb-list-item-padding-horizontal;
      border-radius: 0.2rem;
      background: #fff;
      box-shadow: $app-content-box-shadow;
      cursor: pointer;
      user-select: none;
      transition: box-shadow 0.2s ease-in-out;

      &:hover {
        &:not(.--disabled) {
          box-shadow: $app-content-box-shadow-hover;
        }
      }

      &.--disabled {
        color: #bbb;
        cursor: default;

        .hb-list-item-colum.--accessory.--disclosure {
          color: #ccc;
        }
      }

      .hb-list-item-columns-wrap,
      .hb-list-item-columns {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: calc(#{$hb-list-item-column-spacing} * -1);
        margin-left: calc(#{$hb-list-item-column-spacing} * -1);

        > *,
        > .hb-list-item-colum {
          flex-basis: 100%;
          margin: 0 $hb-list-item-column-spacing;
        }
      }

      .hb-list-item-columns-wrap {
        justify-content: space-between;

        > *,
        > .hb-list-item-colum {
          flex-basis: auto;
          max-width: 70%;
        }
      }

      .hb-list-item-colum {
        &.--accessory {
          max-width: 15px;

          &.--disclosure {
          }
        }
      }
    }
  }
}

.hb-list-item-colum {
  .hb-list-item-column--chip {
    padding: 0.2rem 0.4rem;
    border-radius: 0.7rem;
    background: #f0f0f0;
    font-size: $font-size-xs;

    &.--color-primary {
      background-color: $color-primary;
      color: #ffffff;
    }

    &.--color-fade-out {
      background-color: #ccc;
      color: #fff;
    }

    &.--color-black {
      background-color: #404040;
      color: #ffffff;
    }

    &.--color-success {
      background-color: $app-color-success;
      color: #ffffff;
    }

    &.--color-warning {
      background-color: $app-color-warning;
      color: #ffffff;
    }

    &.--color-danger {
      background-color: $app-color-danger;
      color: #ffffff;
    }
  }
}
